<template>
  <v-container fluid>
    <base-material-card
      icon="mdi-clipboard-text"
      title="1.3 อายุราชการ"
      class="px-5 py-3"
    >
      <v-row>
        <v-col cols="12">
          <v-card :loading="loading">
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-card-text>
              <v-row align="center" class="mx-0">
                <div class="grey--text ms-4">
                  ตำแหน่ง : {{ user_status_type }}
                </div>
              </v-row>

              <div class="my-4">
                <h2>
                  วันที่บรรจุ :
                  {{
                    dmy_app_cal
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                  <br />
                  อายุราชการ : {{ get_gov_Age }}
                </h2>
              </div>
              วันที่ ปฏิบัติงาน ณ สังกัดปัจจุบัน :
              {{
                users.date_app_now          
              }}
              <br />
              อายุราชการ ณ สังกัดปัจจุบัน : {{ get_govMonth_Age }}
            </v-card-text>

            <v-divider class="mx-4"></v-divider>
          </v-card>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
import { constants } from 'crypto';
export default {
  name: "HRvecServiceAge",

  data() {
    return {
      ApiKey: "HRvec2021",
      users: [],
      loading: false,
      selection: 1
    };
  },

  async mounted() {
    let result;
    this.loading = true;
    let useression = JSON.parse(sessionStorage.getItem("user")) || 0;
    result = await this.$http
      .post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: useression.id_card
      })
      .finally(() => (this.loading = false));
    this.users = result.data;
  },

  methods: {
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    }
  },
  computed: {
    time_stamp() {
      const d = new Date();
      let time = Math.floor(d.getTime() / 1000);
      return time;
    },
    user_status_type() {
      let user_status = this.users.user_status;
      let result;
      if (user_status == "teacher") {
        result = "ข้าราชการครู";
      } else if (user_status == "director") {
        result = "ผู้อำนวยการวิทยาลัย";
      } else if (user_status == "se_director") {
        result = "รองผู้อำนวยการวิทยาลัย";
      } else if (user_status == "S") {
        result = "ศึกษานิเทศก์";
      }
      return result;
    },
    dmy_app_cal() {
      let month = this.users.appoin_month;
      let day = this.users.appoin_day;
      let year = this.users.appoin_year - 543;
      let result = year + "-" + month + "-" + day;
      return result;
    },
    month_now() {
      const d = new Date();
      let month = d.getMonth() + 1;
      return month;
    },

    cal_age_gov() {
      const today = new Date();
      const birthDate = new Date(this.dmy_app_cal);
      const yearsDifference = today.getFullYear() - birthDate.getFullYear();
      if (
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() &&
          today.getDate() < birthDate.getDate())
      ) {
        return yearsDifference - 1;
      }
      return yearsDifference;
    },

    get_govMonth_Age() {
      let DOB = new Date(String(this.users.date_app_now));
      let today = new Date();
      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;


      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;     
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
  
        if (DOB.getMonth() === today.getMonth()) 
        months = 0;
        else 
        months = totalMonths % 12;
    

      }
      let age = years + " ปี " + months + " เดือน " + days + " วัน";
      return age;
    },

    get_gov_Age() {
      let yearsApp = parseInt(this.users.appoin_year) -543
      let monthApp = this.users.appoin_month
      let dayApp = this.users.appoin_day
      let dateTimeApp = String(yearsApp + "-" + monthApp + "-" + dayApp)
   
      let startDate = new Date(new Date(String(dateTimeApp)));
      const endingDate = new Date()      
      let endDate = new Date(endingDate);
      if (startDate > endDate) {
        const swap = startDate;
        startDate = endDate;
        endDate = swap;
      }
      const startYear = startDate.getFullYear();

      // Leap years
      const february =
        (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
          ? 29
          : 28;
      const daysInMonth = [
        31,
        february,
        31,
        30,
        31,
        30,
        31,
        31,
        30,
        31,
        30,
        31
      ];

      let yearDiff = endDate.getFullYear() - startYear;
      let monthDiff = endDate.getMonth() - startDate.getMonth();
      let dayDiff = endDate.getDate() - startDate.getDate();

      if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
      }

      if (dayDiff < 0) {
        if (monthDiff > 0) {
          monthDiff--;
        } else {
          yearDiff--;
          monthDiff = 11;
        }
        dayDiff += daysInMonth[startDate.getMonth()];
      }

      let age = yearDiff + " ปี " + monthDiff + " เดือน " + dayDiff + " วัน";
      return age;


    
    }
  }
};
</script>

<style lang="scss" scoped></style>
